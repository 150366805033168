<template>
    <div ref="leafletMap" class="leaflet-map" />
<!--    <div>
    <div ref="leafletMap" class="leaflet-map" />
         
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
   integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
   crossorigin=""/>
        <! -- Make sure you put this AFTER Leaflet's CSS -- >
    <script type="application/javascript" src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
    integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
    crossorigin=""></script> 
    </div>-->
</template>

<script>
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';

window.L = L; // compatibility

export default {
  name: "LeafletMap",
  components: {},
  props: {
    autoLoad: { type: Boolean, default: true },
    zoom: Number,
    center: Array,
    loader: Function,
  },
  data: function () {
    return {
      map: null,
      mapOptions: {
        zoomSnap: 0.25,
        maxZoom: 15,
        dragging: !L.Browser.mobile,
        tap: !L.Browser.mobile,
        touchZoom: true,
      },
      markerIcon: window.L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        // specify the path here
        iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
        shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
      }),
    };
  },
  async mounted() {
    if (this.autoLoad) {
        await this.initMap();
    }
  },
  methods: {
    async ensureLoaded() {
      if (!this.map) {
        await this.initMap();
      }
    },
    async initMap() {
      if (this.map || !this.$refs.leafletMap) {
        return;
      }

      var options = this.mapOptions;
      if (this.zoom) {
          options = { ...options, zoom: this.zoom};
      }
      if (this.center) {
          options = { ...options, center: this.center};
      }

      console.log('loading LEAFLET ', this.$refs.leafletMap, 'L:', L, 'L.:', L.map);
      //await this.$nextTick();
      this.map = L.map(this.$refs.leafletMap, options);
      this.addDefaultTileLayer(this.map);
      if (this.loader) {
          await this.loader(this);
      }
      //await this.$nextTick();
      //this.map.invalidateSize();
    },
    addPolyline(latLngs, options) {
        return L.polyline(latLngs, options).addTo(this.map);
    },
    addCircle(latLng, options) {
        return L.circle(latLng, options).addTo(this.map);
    },
    addMarker(latLng, title, icon, popup) {
      const markerOptions = {
        title: title,
        icon: icon || this.markerIcon,
      };

        const marker = L.marker(latLng, markerOptions);
        if (popup) {
          marker.bindPopup(popup);
        }
        this.map.addLayer(marker);
        return marker;
    },
    addDefaultTileLayer(map) {
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 17,
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>