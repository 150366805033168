import i18n from '@/i18n'

export default {
    
    mergeAndFilterResultColumns(race, meta, cols, isMobileView) {
        var cols = this.mergeResultColumns(race, cols);

        cols.find(x => x.type == 'GENDER').visible &= meta && meta.has_genders;
        cols.find(x => x.type == 'CATEGORY').visible &= meta && meta.categories && meta.categories.length > 0;
        cols.find(x => x.type == 'TIME').visible &= race.scoring != 'TIME';
        //cols.find(x => x.type == 'SCORE').visible &= race.scoring != 'TILES'; // always hide the score for tiles (it's hard-coded to 1)
        //cols.find(x => x.type == 'DISTANCE').visible |= race.scoring == 'TIME';

        return cols.filter(x => x.visible && (x.visible_mobile || !isMobileView));
    },

    mergeResultColumns(race, cols) {
        cols = cols || [];
        const defaultColumns = this.getDefaultResultColumns(race);
        const badgeColumns = cols.filter(x => x.type == 'BADGE');
  
        const mergedCols = [];
        var idx = 1;
        for (const col of defaultColumns) {
          var configured = cols.find(x => x.type == col.type);
          var visible = configured && configured.hasOwnProperty('visible') ? configured.visible : col.default;
          const merged = {
            ...col, // start with defaults so that they can be overwritten
            rank: configured && configured.hasOwnProperty('rank') ? configured.rank : idx,
            default_rank: idx,
            badge_id: configured && configured.hasOwnProperty('badge_id') ? configured.badge_id : null,
            caption: configured && configured.hasOwnProperty('caption') ? configured.caption : null,
            visible: visible,
            visible_mobile: configured && configured.hasOwnProperty('visible_mobile') ? configured.visible_mobile : visible,
          };
          mergedCols.push(merged);
          idx++;
          //if (col.type === 'CUSTOM') console.log('CUSTOM col. Default:', col, 'configured', configured, 'merged:', merged);
        };
        return [...mergedCols, ...badgeColumns].sort((x, y) => x.rank - y.rank);
      },

      getColumnCaption(column) {
        return column.caption ? column.caption : (column.caption_key ? i18n.t('results.cols.'+column.caption_key) : (column.caption || '') )
      },

      getDefaultResultColumns(race) {
        const isCumulative = race.scoring == 'DISTANCE' || race.scoring == 'ELEVATION' || race.scoring == 'CALORIES' || race.scoring == 'TIME' || race.scoring == 'STAIRS' || race.scoring == 'BADGES';
        const isSteps = race.scoring == 'STEPS';
        var scoringCaptionKey = 'score';
        var scoringCaption = null;
        switch (race.scoring) {
          case 'RESULT':
          case 'TRACK':
            scoringCaptionKey = 'time';
            break;
          case 'DISTANCE':
            scoringCaptionKey = 'distance';
            break;
          case 'ELEVATION':
            scoringCaptionKey = 'elevation';
            break;
          case 'CALORIES':
            scoringCaptionKey = 'calories';
            break;
          case 'STEPS':
            scoringCaptionKey = 'steps';
            break;
          case 'TIME':
            scoringCaptionKey = 'total-time';
            break;
          case 'STAIRS':
            scoringCaptionKey = 'stairs';
            break;
          case 'BADGES':
            scoringCaptionKey = 'badges';
            break;
          case 'CUSTOM':
            scoringCaption = race.custom;
            break;
        }
  
        var secondaryScoringCaptionKey = 'score';
        if (race.scoring === 'TILES') {
          secondaryScoringCaptionKey = 'tiles';
        }
        switch (race.secondary_scoring) {
          case 'DURATION':
          case 'DURATION_FASTEST':
            secondaryScoringCaptionKey = 'time';
            break;
          case 'DISTANCE':
            secondaryScoringCaptionKey = 'distance';
            break;
          case 'ELEVATION':
            secondaryScoringCaptionKey = 'elevation';
            break;
          case 'ELEVATION':
            secondaryScoringCaptionKey = 'elevation';
            break;
          case 'STEPS':
            secondaryScoringCaptionKey = 'steps';
            break;
          case 'DAYS_ACTIVITIES':
            secondaryScoringCaptionKey = 'days-with-activity';
            break;
          case 'LAST_ACTIVITY_DATE':
            secondaryScoringCaptionKey = 'last-activity';
            break;
        }
  
        return [
          { type: 'POSITION', default: !race.participation, css_class: 'col-position', caption_key: 'overall' },
          { type: 'BIB', default: race.type === 'EXTERNAL', css_class: 'col-position', caption_key: 'bib' },
          { type: 'AVATAR', default: race.type !== 'EXTERNAL', css_class: 'col-avatar', hint: 'User profile picture' },
          { type: 'NAME', default: true, css_class: 'col-name', caption_key: race.team ? 'team' : 'participant' },
          { type: 'AWARD', default: false, css_class: '', caption_key: 'award', hint: 'Award (and award category)',  },
          { type: 'GENDER', default: true, css_class: 'col-gender', caption_key: 'gender', hint: 'Gender and position' },
          { type: 'CATEGORY', default: !race.team, css_class: 'col-category', caption_key: 'category', hint: 'Category/age grade and position' },
          { type: 'SCORE', default: true, css_class: '', caption_key: scoringCaptionKey, caption: scoringCaption },
          { type: 'SECONDARY_SCORE', default: false, css_class: '', caption_key: secondaryScoringCaptionKey },
          { type: 'PROGRESS', default: false, css_class: '', caption_key: 'progress' },
          { type: 'TIME', default: isCumulative, disabled: isSteps, css_class: '', caption_key: isCumulative ? 'total-time' : 'time' },
          { type: 'DISTANCE', default: false, disabled: isSteps, css_class: '', caption_key: 'distance' },
          { type: 'ELEVATION', default: false, css_class: '', caption_key: 'elevation' },
          { type: 'LAST_ACTIVITY_DATE', default: race.type !== 'EXTERNAL', css_class: '', caption_key: isCumulative ? 'last-activity' : 'date' },
          { type: 'PACE', default: false, disabled: isSteps, css_class: '', caption_key: isCumulative ? 'avg-pace' : 'pace' },
          { type: 'SPEED', default: false, disabled: isSteps, css_class: '', caption_key: isCumulative ? 'avg-speed' : 'speed' },
          { type: 'STEPS', default: false, disabled: isSteps, css_class: '', caption_key: 'steps' },
          { type: 'CUSTOM', default: false, disabled: isSteps, css_class: '', caption: race.custom, hint: 'Custom score value' },
          //{ type: 'BONUS', default: false, css_class: '', caption_key: 'bonus' },
          { type: 'CITY', default: false, css_class: '', caption_key: 'city' },
          { type: 'STATE', default: false, css_class: '', caption_key: 'state' },
          { type: 'COUNTRY', default: false, css_class: '', caption_key: 'country' },
          { type: 'ACTIVE_DAYS', default: false, css_class: '', caption_key: 'days-with-activity' },
          { type: 'UDF_1', default: false, css_class: '', hint: 'User Defined Field 1',  },
          { type: 'UDF_2', default: false, css_class: '', hint: 'User Defined Field 2',  },
          { type: 'UDF_3', default: false, css_class: '', hint: 'User Defined Field 3',  },
        ];
      },
      
}