<template>
  <div class="chart-container" style="position: relative; height:200px; ">
    <canvas ref="chart"></canvas>
    
  </div>
</template>


<script>

import Chart from 'chart.js/auto';
//import {Chart} from 'chart.js';
import { DateTime, Duration, Interval } from 'luxon';
import 'chartjs-adapter-luxon';
//import colors from 'vuetify/lib/util/colors'

export default {
  name: "RaceHistogramByDate",
  components: {
  },
  props: {
    data: Object,
    label: String,
    minimal: Boolean,
    defaultColor: {
        type: String,
        default: '#111',
    },
    accentColor: {
        type: String,
        default: '#ccc',
    },
  },
  data() {
    return {
        chart: null,
        minValue: 0,
        maxValue: 0,
    };
  },
  async mounted() {
    this.prepareData();
    if (this.data) {
        this.createChart();
    }
  },
  methods: {
    prepareData() {
        console.log('prepareData', this.data, Object.values(this.data));
        var values = Object.values(this.data);
        this.minValue = Math.min(...values);
        this.maxValue = Math.max(...values);
    },
    barColor: function(context, options) {
        var ratio = (context.raw.y - this.minValue) / (this.maxValue - this.minValue);
        //console.log('barcolor', context.raw.y, this.minValue, this.maxValue, ratio);
        //var color = this.defaultColor;//options.color; // resolve the value of another scriptable option: 'red', 'blue' or 'green'
        //return Chart.helpers.color(color).lighten(0.2);
        //return this.$helpers.getGradientColorAt(this.$vuetify.theme.defaults.light.progress, this.$vuetify.theme.defaults.light.primary, ratio);
        return this.$helpers.getGradientColorAt(this.accentColor, this.defaultColor, ratio);
        //return this.defaultColor;
    },
    createOrRefreshChart() {
        if (this.chart) {
            this.chart.update();
        }
        else {
            this.createChart();
        }
    },
    dayToDate(isoDate) {
        return DateTime.fromISO(isoDate);
    },
    createChart() {
        if (this.chart) {
            return;
        }
        var ctx = this.$refs.chart;
        console.log('CHART creating', ctx, this.data);
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: [{
                    label: this.label,
                    backgroundColor: this.barColor, 
                    data: Object.keys(this.data).map(x => ({x: this.dayToDate(x), y: this.data[x]})),
                    borderColor: this.defaultColor,
                    borderWidth: 1
                }]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day',
                            displayFormats: {
                                minute: "dd-MM"
                            },
                            tooltipFormat: "'Date' yyyy-MM-dd",
                        },
                        ticks: {
                            display: !this.minimal,
                        },
                        grid: {
                            display: !this.minimal,
                        }

                    },
                    y: {
                        display: true,
                        beginAtZero: true,
                        ticks: {
                            display: false,
                            major: {
                                enabled: true,
                            }
                        },
                        grid: {
                            display: !this.minimal,
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    }
                },
            }
        });
    }
  },
  watch: {
    data(val) {
       //this.createChart();
    },
  }
}
</script>