<template>
  <div class="map-container">
    <link rel="stylesheet" type="text/css" href="/flags-css/css/flag-icon.min.css" />
    <div v-if="highlightedTile" class="d-flex pa-2 white highlighted-message" >
      <strong class="pl-2 px-4">{{highlightedTile.name}}</strong>
      <v-spacer/>
      <v-btn small outlined @click="highlightTile(null)">Close</v-btn>
    </div>
    <LeafletMap 
      ref="leaflet"
      style="height: 600px; width: 100%; z-index: 2;" 
      class="leaflet-map"
      :zoom="zoom"
      :auto-load="false"
      :center="startCoord"
      :loader="initMap"
      />

  </div>
</template>

<script>
// Todo: add swipe/scroll notification from https://codepen.io/surisdziugas/pen/LzXPwz

import LeafletMap from "@/components/LeafletMap";
import { EventBus } from '@/plugins/eventbus.js';
import tileService from "@/services/tileService";
import { polygon as turfPolygon } from "@turf/helpers"; 
import turfDifference from "@turf/difference";
import PolylineUtil from "@/plugins/Polyline.encoded.js";
//import LeafletExtraStyles from "@/utils/leaflet-extrastyles.js";

export default {
  name: "TileMap",
  components: {
    LeafletMap,
  },
  props: {
    tileset: {
      type: String,
      default: 'v1',
    },
    country: String,
    preventAutoLoad: Boolean,
    focusTile: Object,
    focusTiles: Array,
    tileOptionCreator: Function,
    tileSelectedEventName: String,
  },
  data() {
    return {
      tiles: null,
      tile: null,
      showZoomAlert: true,
      highlightedTile: null,
      minZoom: 7,
      zoom: 8,
      polygons: [],
      logos: [],
      bounds: null,
      map: null,
      startCoord: null,
      mapInitialized: false,
    };
  },
  async mounted() {
    window.EventBus = EventBus; // so we can access it from leaflet popup
    var location = this.$store.state.ipCoord || this.$cookies.get('tilemapcenter');
    if (!this.preventAutoLoad) {
      if (this.country) {
        var countryInfo = (await tileService.getCountry(this.country)).data;
        const bounds = window.L.latLngBounds([countryInfo.sw.lat, countryInfo.sw.lng], [countryInfo.ne.lat, countryInfo.ne.lng]);
        //console.log('Country', country, ' info:', countryInfo, 'bounds', bounds);
        location = bounds.getCenter();
      }
      else if (!location) {
        //console.log('Looking up ip');
        var ip = (await axios.get('https://api.ipify.org?format=json')).data.ip;
        console.log('Requesting location for IP', ip);
        location = (await tileService.me(ip)).data;
        this.$store.commit('setIpCoord', location); 
        this.$cookies.set('tilemapcenter', location);
      }
      this.startCoord = [location.lat, location.lng];
      console.log('Location loaded: ', this.startCoord);
      this.mapInitialized = true;
    }
    await this.$refs.leaflet.initMap();
  },

  methods: {
    async initMap(leaflet) {
      console.log('loading CUSTOM INIT ', leaflet, leaflet.map);
      this.map = leaflet.map;
      this.map.invalidateSize();
      //this.addTileLayers(this.map);
      if (this.bounds) {
        this.map.fitBounds(this.bounds);
      }
      else if (this.startCoord) {
        this.map.setView(this.startCoord, this.zoom);
      }
      await this.loadTilesInMap();
      this.map.on('moveend', this.loadTilesInMap);
      this.map.on('zoomend', this.resizeLogosOnZoom);
      this.map.on('zoomend', this.updateLeafletClass);
      this.map.on('zoomend', this.colorizeTiles);
      this.$refs.leaflet.$el.addEventListener("touchstart", this.onTwoFingerDrag);
      this.$refs.leaflet.$el.addEventListener("touchend", this.onTwoFingerDrag);
    },
      addTileLayers(map) {
      window.L.tileLayer('http://tile.stamen.com/watercolor/{z}/{x}/{y}.jpg', {
          attribution: this.attribution,
          minZoom: 0,
          maxZoom: 7
      }).addTo(map);
      window.L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: this.attribution,
          minZoom: 7,
          maxZoom: 16
      }).addTo(map);
    },

    async loadTiles(tiles) {
      await this.$refs.leaflet.ensureLoaded();
      console.log('loading TILES ', this.map);
      //console.log('Loading custom tiles', tiles, 'turfDifference', 'polygon',polygon);
      // find center
      // find bounds
      if (tiles && tiles.length > 0) {
        var bounds = window.L.latLngBounds();
        const earth = [[[180, -180],  [180, 180],  [-180, 180],  [-180, -180], [180, -180]]];
        var earthWithTileHoles = turfPolygon(earth);
        for (var tile of tiles) {
          bounds.extend(tile.v);
          //console.log('PUSHING HOLE', tile.v);
          const coords = tile.v.map(x => [x.lng, x.lat]);
          const poly = turfPolygon([[...coords, [tile.v[0].lng, tile.v[0].lat]]]);
          earthWithTileHoles = turfDifference(earthWithTileHoles, poly);
        }

        //console.log('earthWithTileHoles', earthWithTileHoles);
        //window.L.polygon(earthWithTileHoles.geometry.coordinates, { color: '#000000', opacity: 1, fillOpacity: 0.3, weight: 5, stroke: true, fill: true }).addTo(this.map);

        this.map.fitBounds(bounds);
        //this.map.setMinZoom(this.map.getZoom());
        this.map.setMaxBounds(bounds.pad(.2 /* allow a little bit of panning to orientate */));

        this.tiles = tiles;
        await this.addTilesToMap();

        // background layer is added as last, as addTileToMap will clear all layers
        var backgroundStyle = {
            color: "#fff",
            weight: 0,
            opacity: 1,
            fillColor: "#000",
            fillOpacity: 0.5,
        };
        var bufferedLayer = L.geoJSON(null, { style: backgroundStyle });
        bufferedLayer.addData(earthWithTileHoles);
        bufferedLayer.addTo(this.map);
        //console.log('Adding background layer', bufferedLayer);
      }
    },
    async loadTilesInMap() {
      if (!this.mapInitialized || (this.focusTile = null && this.focusTiles == null)) {
        return;
      }
      this.$cookies.set('tilemapcenter', this.map.getCenter());
      var bounds = this.map.getBounds().pad(.1 /* 10% */);
      var zoom = this.map.getZoom();
      this.$emit('mapPanned', this.map); 
      
      console.log('Map view port changed to', bounds, 'with zoom', zoom);
      this.showZoomAlert = zoom <= this.getMinZoom(this.tileset);
      if (this.showZoomAlert) {
        this.$refs.leaflet.$el.classList.add('zoom-in-message')
        this.$refs.leaflet.$el.classList.add('with-message')
      } else {
        this.$refs.leaflet.$el.classList.remove('zoom-in-message')
        this.$refs.leaflet.$el.classList.remove('swiping-message')
      }
      if (!this.showZoomAlert) {
        var options = {minLng: bounds.getWest(), maxLng: bounds.getEast(), minLat: bounds.getSouth(), maxLat: bounds.getNorth(), limit: 500};
        this.tiles = (await tileService.search(this.tileset, options)).data.data;
        await this.addTilesToMap();
      }
    },

    getMinZoom(tileset) {
      if (tileset === 'v0')
        return 2;
      return this.minZoom;
    },

    async resizeLogosOnZoom() {
      var currentZoom = this.map.getZoom();
      for (var logo of this.logos) {
          console.log('RESIZING LOGO', currentZoom, logo, logo.options, logo.options.icon);
          logo.options.icon.options.iconSize = [currentZoom*10, currentZoom*10];
      }
    },

    async updateLeafletClass() {
      var currentZoom = this.map.getZoom();
      console.log('Zooming', currentZoom);
      this.$refs.leaflet.$el.classList.remove('zoom-md');
      this.$refs.leaflet.$el.classList.remove('zoom-lg');
      this.$refs.leaflet.$el.classList.remove('zoom-xl');
      if (currentZoom >= 6.5) {
        this.$refs.leaflet.$el.classList.add('zoom-md');
      }
      if (currentZoom >= 8.5) {
        this.$refs.leaflet.$el.classList.add('zoom-lg');
      }
      if (currentZoom >= 10) {
        this.$refs.leaflet.$el.classList.add('zoom-xl');
      }
    },

    async colorizeTiles() {
      if (this.tileOptionCreator == null) {
        return;
      }

      var currentZoom = this.map.getZoom();
      for (var poly of this.polygons) {
        //console.log('poly color', poly.options.tile, currentZoom);
        var style = this.tileOptionCreator(poly.options.tile, currentZoom);
        poly.setStyle(style);
      }

    },

    async addTilesToMap() {
      //var options = {minLng:0, maxLng:300, minLat: 50, maxLat: 55, limit: 500};
      //this.tiles = (await tileService.search(options)).data.data;
      this.clearMap(this.map);
      //console.log(`Loading ${this.tiles.length} tiles...`);
      this.polygons = [];
      this.logos = [];
      var self = this;
      for (var i = 0; i < this.tiles.length; i++) {
        var tile = this.tiles[i];
        var hexagon = tile.v.map(x => window.L.latLng(x.lat, x.lng));
        const fillOpacity = this.getFillOpacity(tile);
        const fillColor =  'white';
        //window.L.polygon(hexagon, { color: 'white', weight: this.getStrokeWeight(tile)*3, stroke: true, fill: false }).addTo(this.map);
        var baseOptions = { customId: tile.id, tile: tile, className: 'tile-polygon' };
        var options = this.tileOptionCreator ? { ...baseOptions, ...this.tileOptionCreator(tile, this.map.getZoom())} : { ...baseOptions, color: 'black', weight: this.getStrokeWeight(tile), stroke: true, fill: true, fillOpacity: fillOpacity, fillColor: fillColor };
        options.defaultFillOpacity= options.fillOpacity;
        //console.log('tile style', options, 'MERGE WITH', this.tileOptionCreator(tile));
        var polygon = window.L.polygon(hexagon, options);//.addTo(this.map);
        polygon.addTo(this.map);
        //const clickEventHandler = async (e) => await self.loadTile(e.target.options.customId, e.target)
        polygon.on('click', async (e) => await self.loadTile(e.target.options.customId, e.target));
        this.polygons.push(polygon);

        if (tile.img) {
          // Add the logo at the center of the tile, take at most .75% of the tile width at min zoom level
          /*
          const point2 = this.map.project(hexagon[1], this.map.getMinZoom());
          const point6 = this.map.project(hexagon[5], this.map.getMinZoom());
          const tileWidth = Math.abs(point2.x - point6.x);
          const groupLogoIcon = window.L.icon({
            iconSize: [tileWidth * .75, tileWidth * .75],
            iconAnchor: [tileWidth * .375, tileWidth * .375],
            popupAnchor: [0, tileWidth * .75],
            className: 'tile-icon',
            iconUrl: tile.img,
          });
          const markerOptions = {
            title: 'Owners',
            icon: groupLogoIcon,
            clickable:false,
            customId: tile.id,
            v2: hexagon[1],
            v6: hexagon[5],
            polygon: polygon,
          };
          //console.log('PROJECT', this.map.getZoom(), this.map.getMinZoom(), point2, point6, Math.abs(point2.x - point6.x));
          //const logoMarker = window.L.marker(window.L.latLng((hexagon[0].lat+hexagon[3].lat)/2, hexagon[0].lng), markerOptions);
          //logoMarker.on('click', async (e) => await self.loadTile(e.target.options.customId, e.target.options.polygon));
          //logoMarker.addTo(this.map);
          //this.logos.push(logoMarker);
          */
          window.L.imageOverlay(tile.img, hexagon, { interactive: false, zIndex: 2, className: 'tile-img' }).addTo(this.map);
        }
      }
    },

    getStrokeWeight(tile) {
      return this.isInFilter(tile) ? 1 : .3
    },
    getFillOpacity(tile) {
      return this.isInFilter(tile) ? .05 : .1
    },
    isInFilter(tile) {
      const cc = tile.cc || tile.country_code;
      return this.country && (cc && cc.toLowerCase() == this.country.toLowerCase());
    },

    async loadTile(id, polygon) {
      if (this.tile == null || this.tile.id !== id) {
        console.log(`Loading tile ${id}...`);
        this.tile = null;
        if (this.tiles) {
          this.tile = this.tiles.find(x => x.id == id);
        }
        if (!this.tile || !this.tile.name) {
          this.tile = (await tileService.get(id)).data;
        }
      }
      this.$emit('selected', this.tile, polygon); 
    },

    async highlightTile(tile, highlightOn = true, showPopup = true) {
      var polygon;
      for (var poly of this.polygons) {
        if (tile && poly.options.customId == tile.id) {
          poly.setStyle({ fillOpacity: this.getFillOpacity(tile) });
          polygon = poly;
        }
        else {
          poly.setStyle({ fillOpacity: highlightOn && tile ? .8 : poly.options.defaultFillOpacity });
        }
      }
      this.highlightedTile = tile;
      if (highlightOn) {
        console.log('emitting tilemap-highlight-tile', tile);
        EventBus.$emit('tilemap-highlight-tile', tile);
      }
      if (polygon && showPopup) {
        const content = this.tileSelectedEventName 
                        ? `<h3 class="mb-2">${tile.name || 'Tile'}</h3><p class="my-0"><a class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default primary--text" onclick="EventBus.$emit('${this.tileSelectedEventName}', '${tile.id}');"><span class="v-btn__content">View &raquo;</span></a></p>`
                        : `<h3 class="mb-2">${tile.name || 'Tile'}</h3><p class="my-0"><a class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default primary--text" href="/tiles/${tile.id}"><span class="v-btn__content">View &raquo;</span></a></p>`;

        var popup = window.L.popup()
          .setLatLng(polygon.getCenter())
          .setContent(content)
          .openOn(polygon);
        var self = this;
        this.map.openPopup(popup);
        this.map.on('popupclose', async (e) => await self.highlightTile(null, false));
      }
    },

    closePopups: function() {
      this.map.closePopup();
    },

    clearMap: function(m) {
      //console.log('Clearing tile map.');
        for(const i in m._layers) {
            if(m._layers[i]._path != undefined) {
                try {
                    m.removeLayer(m._layers[i]);
                }
                catch(e) {
                    console.log("problem with " + e + m._layers[i]);
                }
            }
        }
    },
    onTwoFingerDrag: function (e) {
      //console.log('map?s', e);
      if (e.type === 'touchstart' && e.touches.length === 1) {
        e.currentTarget.classList.add('swiping-message')
        e.currentTarget.classList.add('with-message')
      } else {
        e.currentTarget.classList.remove('with-message')
        e.currentTarget.classList.remove('swiping-message')
      }
    },
  },
  watch: {
    async focusTile() {
      if (!this.focusTile) return;

      this.tile = this.focusTile;
      this.clearMap(this.map);

      var hexagon = this.focusTile.vertices.map(x => window.L.latLng(x.lat, x.lng));
      var polygon = window.L.polygon(hexagon, {color: 'white', weight: 5, stroke: true, fill: false});//.addTo(this.map);
      polygon.addTo(this.map);
      this.map.fitBounds(polygon.getBounds());
      this.map.setMinZoom(this.map.getZoom());
      this.map.setMaxBounds(polygon.getBounds().pad(.1 /* 10% */));
      window.L.polygon(hexagon, {color: 'black', weight: 2, stroke: true, fill: false}).addTo(this.map);

      this.tiles = (await tileService.getAround(this.focusTile.id)).data.data;
//console.log('around', this.tiles);
      for (const tile of this.tiles) {
        hexagon = tile.v.map(x => window.L.latLng(x.lat, x.lng));
        window.L.polygon(hexagon, {fill: true, fillColor: 'white', fillOpacity: 1, stroke: false, color: '#F46524', weight: 1 })
          .addTo(this.map)
          .on('click', () => this.$router.push({ name: 'tile', params: { id: tile.id } }));
      }
    },
  },
  computed: {
    /*...mapGetters({
      user: "user"
    })*/
  },

};
</script>
<style lang="scss">

.leaflet-map { 
  position: relative;
  z-index:9;
   &.swiping-message::after {
    content: 'Use two fingers to move the map';
   }
   &.zoom-in-message::after {
    content: 'Zoom in to load the tiles';
   }
   &.with-message::after {
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 300;
    justify-content:center;
    display: flex;
    align-items: center;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    pointer-events:none;
  }
  svg {
    z-index: 1;
  }
  .tile-polygon {
    filter: drop-shadow(0px 0px 3px #fff);
  }
  .tile-img {
    z-index: 2;
    object-fit: scale-down;
    filter: drop-shadow(0px 0px 1px #fff);
    padding: 20px !important;
  }
}
.leaflet-map.zoom-md .tile-img { padding: 30px !important; }
.leaflet-map.zoom-lg .tile-img { padding: 75px !important; }
.leaflet-map.zoom-xl .tile-img { padding: 200px !important; }
.map-container {
  position: relative;
  .highlighted-message { position: absolute; left: 25%; bottom: 10px; min-width:50%; z-index: 3; border: #333 solid 1px; border-radius: 4px; place-items: flex-end;  }
}

</style>

