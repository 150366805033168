<template>
  <v-dialog v-model="visible">
    <v-card>  
      <v-card-title class="display-1">sodisp Points</v-card-title>
      <v-card-text class="mt-4 pb-0">
        <p>
        <strong>sodisp Points</strong> are calculated based on various social distance related parameters.
        </p>
        <p class="mb-0">For events, the points are scaled to the race distance to make a fair comparison between workouts with a different distance.</p>
      </v-card-text>
      <v-card-title class="subtitle mt-0">Bonus points</v-card-title>
      <v-card-text>
        To encourage working out in a responsible way sodisp calculates bonus points when social distancing rules are followed. 
        Score more points by avoiding busy hours of the day or weekend days. Also try to avoid crowded places and explore more quiet places in your proximity.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="visible = false">Close</v-btn>
      </v-card-actions>      
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "PointsInfoDialog",
  props: {
  },
  data: function() {
    return {
      visible: false,
    };
  },
  mounted() {
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>