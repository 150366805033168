<template>
  <div>
    <v-skeleton-loader v-if="!user || $store.getters.loading" type="table"></v-skeleton-loader>
    <v-card-title v-if="validResults && validResults.length > 0" class="subtitle">{{title || $t('profile.results.title')}}</v-card-title>
    <v-simple-table fixed-header v-if="validResults && validResults.length > 0" >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{$t('results.cols.position')}}</th>
            <th class="text-left">{{$t('results.cols.event')}}</th>
            <th class="text-left">{{$t('results.cols.score')}}</th>
            <th class="text-left">{{$t('results.cols.progress')}}</th>
            <th v-if="!$vuetify.breakpoint.xs" class="text-left">{{$t('results.cols.last-activity')}}</th>
            <th class="text-left">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in validResults" :key="idx" @click="openResultPopup(item)">
            <td><v-chip v-if="item.result.pos" color="accent">#{{ item.result.pos }}</v-chip></td>
            <td v-if="adminMode">{{ item.race.name }}</td>
            <td v-else><router-link class="ml-1" :to="{name: 'race', params: { id:item.event.id, raceId: item.race.id }}">{{ item.event.name }} ({{ item.race.name }})</router-link></td>
            <td  v-if="item.race.scoring == 'DISTANCE'" class="pr-0">
              <v-chip outlined color="green" class="">{{ item.result.score_value | distance(item.event.unit, item.race.activity_types) }}</v-chip>
              <v-icon v-if="item.result.verified" color="green" class="ml-2" :title="$t('results.verified-result')">fal fa-location</v-icon>
            </td>
            <td v-else-if="item.race.scoring == 'ELEVATION'" class="pr-0">
              <v-chip outlined color="green" class="">{{ item.result.score_value | distance(item.event.unit) }}</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'ELEVATION_LOSS'" class="pr-0">
              <v-chip outlined color="green" class="">{{ item.result.score_value | distance(item.event.unit) }}</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'CALORIES'" class="pr-0">
              <v-chip outlined color="green" class="">{{ item.result.score_value }} kcal</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'TIME'" class="pr-0">
              <v-chip outlined color="green" class="">{{ item.result.score_value | duration }}</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'STAIRS'" class="pr-0">
              <v-chip outlined color="green" class="">{{ item.result.score_value }} stairs</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'STEPS'" class="pr-0">
              <v-chip outlined color="green" class="">{{ item.result.score_value | int }} steps</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'BADGES'" class="pr-0">
              <v-chip outlined color="green" :title="$helpers.getScoreTooltipForDisplay($options, item.race, item.result, item.event.unit)">{{ $helpers.getValueForDisplay($options, item.race, item.result, item.event.unit) }}</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'TRAINING_PLAN'" class="pr-0">
              <v-chip outlined color="green" title="Completed workouts">{{ item.result.score_value }} workouts</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'TILES'" class="pr-0">
              <v-chip outlined color="green" title="Score">{{ $helpers.getValueForDisplay($options, item.race, item.result, item.event.unit) }}</v-chip>
            </td>
            <td v-else-if="item.race.scoring == 'CUSTOM'" class="pr-0">
              <v-chip outlined color="green" :title="$helpers.getScoreTooltipForDisplay($options, item.race, item.result, item.event.unit)">{{ $helpers.getValueForDisplay($options, item.race, item.result, item.event.unit) }}</v-chip>
            </td>
            <td v-if="item.race.scoring == 'RESULT'" style="min-width:100px;">{{ (-1 * item.result.score_value) | duration }} </td>
            <td v-if="item.race.scoring == 'TRACK'" style="min-width:100px;">{{ (-1 * item.result.score_value) | duration }} </td>
            <td v-if="item.result.progress || item.result.progress === 0">{{ Math.round(item.result.progress * 100) }}%</td>
            <td v-else class="grey--text">{{$t('shared.n-a')}}</td>
            <td v-if="!$vuetify.breakpoint.xs" class="grey--text">{{ item.result.last_activity | localDate('L', /*inUserTime:*/true) }}</td>
            <td style="min-width:90px;">
              <v-icon color="primary" small>fa-info-circle</v-icon>
              <v-btn v-if="item.race.referral_msg || item.event.referral_msg" text small color="primary" class="ml-2" title="Invite a friend" :to="{name: 'raceInvite', params: {id: item.event.id, raceId: item.race.id}}">{{$t('events.refer-a-friend')}}</v-btn>
              <v-icon v-if="adminMode && item.race.certificate" small color="primary" class="ml-2" title="Preview Certificate" @click.stop="previewCertificate(item)">fa-file-certificate</v-icon>
              <v-icon v-if="adminMode" small color="primary" class="ml-2" title="Recalculate result" @click.stop="recalculateResult(item)">fa-sync</v-icon>
              <v-icon v-if="adminMode" small color="red" class="ml-2" title="Delete result from leaderboard" @click.stop="deleteResult(item)">fa-trash</v-icon>
              <v-icon v-if="adminMode && item.race.team" small color="red" class="ml-2" title="Delete profile from team" @click.stop="deleteProfileFromTeam(item)">fa-user-minus</v-icon>
              <ShareButton v-if="!adminMode" small :icon="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" :text="`My result at the ${item.event.name} - Check sodisp.com`" color="primary" :route="{name: 'raceResult', params: { id:item.event.id, raceId: item.race.id, resultId: item.result.id}}" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="emptyResults && emptyResults.length > 0">
      <v-card-title class="subtitle pb-0 mb-n4">{{title || $t('profile.results.upcoming')}}</v-card-title>
      <v-list three-line>
        <template v-for="(item, index) in emptyResults">
          <v-divider v-if="index > 0" :key="`d-${index}`" />
          <v-list-item :key="index">
            <v-list-item-avatar v-if="item.event.logo">
              <v-img :src="item.event.logo"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="item.event.name"></v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="$helpers.isActiveEvent(item.event)" outlined class="accent--text mr-2">{{$t('profile.results.active-now')}}</span>
                <span v-else-if="$helpers.isFutureEvent(item.event)" outlined class="accent--text mr-2">{{$t('profile.results.opens-in')}} {{$helpers.getStartDurationForEvent(item.event) | duration(true)}}</span>
                <span v-if="item.event.instructions">• <vue-markdown class="markdown d-inline-block" :html="false">{{item.event.instructions }}</vue-markdown></span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon :to="{ name: 'eventWelcome', params: { id:item.event.id }}">
                <v-icon color="accent lighten-1">fa-info-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>      
    </div>
    <RaceResultDetailsDialog ref="resultPopup" :event="null" :race="null"/>
    <v-dialog v-model="previewCertificateDialog" width="600">
      <v-card>
        <v-progress-circular v-if="!certificatePreviewData" indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
        <img v-if="certificatePreviewData" :src="certificatePreviewData" style="max-width:100%;" />
        <v-card-actions>
          <v-btn text @click="previewCertificateDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import assetsService from "@/services/assetsService";
import eventService from "@/services/eventService";
import eventManagerService from "@/services/eventManagerService";
import ShareButton from "@/components/ShareButton";
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import VueMarkdown from '@/components/VueMarkdown.vue'

export default {
  name: "MyRaceResultsOverview",
  components: {
    ShareButton,
    RaceResultDetailsDialog,
    VueMarkdown,
  },
  props: {
    results: Array,
    title: String,
    adminMode: Boolean,
    profileId: String,
  },
  data() {
    return {
      previewCertificateDialog: false,
      certificatePreviewData: null,
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
      }
    },
    async openResultPopup(result) {
      console.log ('opening popup');
      await this.$refs.resultPopup.open(result.result, result.event, result.race);
    },
    async deleteResult(item) {
      if (this.adminMode && confirm(`Are you sure you want to delete the result ${item.result.name} from the leaderboard?`)) {
        var response = (await eventManagerService.deleteResult(item.event.id, item.race.id, item.result.id)).data;
        this.$helpers.toastResponse(this, response, 'Successfully deleted result.');
        console.log('Saved', response);
        if (response.status == "OK") {
          this.$emit('refresh'); 
        }
      }
    },
    async recalculateResult(item) {
      if (this.adminMode && confirm(`Are you sure you want to recalculate the result ${item.result.name}? This may take a few seconds.`)) {
        var response = (await eventManagerService.recalculateResult(item.event.id, item.race.id, item.result.id)).data;
        this.$helpers.toastResponse(this, response, 'Successfully refreshed result.');
        console.log('Recalculation response', response);
        if (response.status == "OK") {
          this.$emit('refresh'); 
        }
      }
    },
    async previewCertificate(item) {
      this.previewCertificateDialog = true;
      var response = await eventManagerService.getCertificatePreview(item.event.id, item.race.id, item.result.id);
      let reader = new FileReader();
      reader.readAsDataURL(response.data); 
      reader.onload = () => {
          this.certificatePreviewData = reader.result;
      };
    },
    async deleteProfileFromTeam(item) {
      if (this.adminMode && confirm(`Are you sure you want to delete this result from the team result ${item.result.name}? This may take a few seconds.`)) {
        var response = (await eventManagerService.deleteProfileFromTeamResult(item.event.id, item.race.id, item.result.id, this.profileId)).data;
        this.$helpers.toastResponse(this, response, 'Successfully removed from team result.');
        if (response.status == "OK") {
          this.$emit('refresh'); 
        }
      }
    },
    

  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV == 'development';
    },
    validResults() {
      return !this.results ? null : this.results.filter(x => x.result);
    },
    emptyResults() {
      return !this.results ? null : this.results.filter(x => !x.result);
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>

<style lang="scss">
</style>

