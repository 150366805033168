import services from "./index";
import qs from "querystring";

export default {
    getTrainingPlans() {
        return services.http.get(`/v1.0/assets/training-plans`);
    },
    getTrainingPlan(id) {
        return services.http.get(`/v1.0/assets/training-plans/${id}`);
    },

}